export enum Permission {
  ADMIN = 'admin',
  CLUSTER = 'cluster',
  FLAVOR = 'flavor',
  CATEGORY_CREATE = 'category.create',
  CATEGORY_UPDATE = 'category.update',
  CATEGORY_DELETE = 'category.delete',
  CATEGORY_VIEW = 'category.view',
  CATEGORY_VISIBLE = 'category.visible',
  CATEGORY_HIDE = 'category.hide',
  CATEGORY_VIEW_NAV = 'category.view.nav',
  CATEGORY_COUPON_HIDE = 'category.coupon.hide',
  CATEGORY_COUPON_VISIBLE = 'category.coupon.visible',
  ARTICLE_CREATE = 'article.create',
  ARTICLE_UPDATE = 'article.update',
  ARTICLE_UPDATE_SIMPLE = 'article.update.simple',
  ARTICLE_DELETE = 'article.delete',
  ARTICLE_VISIBLE = 'article.visible',
  ARTICLE_HIDE = 'article.hide',
  ARTICLE_ACTIVATE = 'article.activate',
  ARTICLE_DEACTIVATE = 'article.deactivate',
  ARTICLE_CONTROL_ACTIVATE = 'article.control.activate',
  ARTICLE_CONTROL_DEACTIVATE = 'article.control.deactivate',
  ARTICLE_PRICE_LOCK = 'article.price.lock',
  ARTICLE_TABLE_COLUMN_SELECTOR = 'article.table.column.selector',
  FOODCARD_IMPORT = 'foodcard.import',
  FOODCARD_EXPORT = 'foodcard.export',
  FOODCARD_SYNC_EXTERNAL = 'foodcard.sync.external',
  ARTICLE_VIEW = 'article.view',
  OPTION_CREATE = 'option.create',
  OPTION_UPDATE = 'option.update',
  OPTION_DELETE = 'option.delete',
  OPTION_VIEW = 'option.view',
  OPTION_VISIBLE = 'option.visible',
  OPTION_HIDE = 'option.hide',
  OPTION_ARTICLE_CREATE = 'option.article.create',
  OPTION_ARTICLE_UPDATE = 'option.article.update',
  OPTION_ARTICLE_UPDATE_SIMPLE = 'option.article.update.simple',
  OPTION_ARTICLE_DELETE = 'option.article.delete',
  OPTION_ARTICLE_VIEW = 'option.article.view',
  OPTION_ARTICLE_ACTIVATE = 'option.article.activate',
  OPTION_ARTICLE_DEACTIVATE = 'option.article.deactivate',
  OPTION_ARTICLE_VISIBLE = 'option.article.visible',
  OPTION_ARTICLE_HIDE = 'option.article.hide',
  OPTION_ARTICLE_PRICE_LOCK = 'option.article.price.lock',
  WORKFLOW_CREATE = 'workflow.create',
  WORKFLOW_UPDATE = 'workflow.update',
  WORKFLOW_DELETE = 'workflow.delete',
  WORKFLOW_VIEW = 'workflow.view',
  TABLE_CREATE = 'table.create',
  TABLE_UPDATE = 'table.update',
  TABLE_DELETE = 'table.delete',
  TABLE_VIEW = 'table.view',
  TABLE_EXPORT = 'table.export',
  CONSTRAIN_CREATE = 'constrain.create',
  CONSTRAIN_UPDATE = 'constrain.update',
  CONSTRAIN_DELETE = 'constrain.delete',
  CONSTRAIN_VIEW = 'constrain.view',
  CONSTRAIN_ACTIVATE = 'constrain.activate',
  CONSTRAIN_DEACTIVATE = 'constrain.deactivate',
  PRINTGROUPS_CREATE = 'printgroup.create',
  PRINTGROUPS_UPDATE = 'printgroup.update',
  PRINTGROUPS_DELETE = 'printgroup.delete',
  PRINTGROUPS_VIEW = 'printgroup.view',
  PRINTGROUPS_ACTIVATE = 'printgroup.activate',
  PRINTGROUPS_DEACTIVATE = 'printgroup.deactivate',
  TAG_CREATE = 'tag.create',
  TAG_UPDATE = 'tag.update',
  TAG_DELETE = 'tag.delete',
  TAG_VIEW = 'tag.view',
  PRINTER_CREATE = 'printer.create',
  PRINTER_UPDATE = 'printer.update',
  PRINTER_RESTART = 'printer.restart',
  PRINTER_DELETE = 'printer.delete',
  PRINTER_VIEW = 'printer.view',
  BILLING_CREATE = 'billing.create',
  BILLING_UPDATE = 'billing.update',
  BILLING_DELETE = 'billing.delete',
  BILLING_VIEW = 'billing.view',
  BILLING_FLAVOR = 'billing.flavor',
  LOG_CREATE = 'log.create',
  LOG_UPDATE = 'log.update',
  LOG_DELETE = 'log.delete',
  LOG_VIEW = 'log.view',
  ROLE_CREATE = 'role.create',
  ROLE_UPDATE = 'role.update',
  ROLE_DELETE = 'role.delete',
  ROLE_VIEW = 'role.view',
  ANALYTICS_CREATE = 'analytics.create',
  ANALYTICS_UPDATE = 'analytics.update',
  ANALYTICS_DELETE = 'analytics.delete',
  ANALYTICS_DAILY_EXPORT = 'analytics.daily.export',
  ANALYTICS_FEATURES_EXPORT = 'analytics.features.export',
  ANALYTICS_VIEW = 'analytics.view',
  ANALYTICS_FLAVOR = 'analytics.flavor',
  ANALYTICS_CUSTOMER_GROUP = 'analytics.customerGroup',
  STATISTICS_CREATE = 'statistics.create',
  STATISTICS_UPDATE = 'statistics.update',
  STATISTICS_DELETE = 'statistics.delete',
  STATISTICS_VIEW = 'statistics.view',
  STATISTICS_FLAVOR = 'statistics.flavor',
  STATISTICS_OVERVIEW = 'statistics.overview',
  STATISTICS_OVERVIEW_EXPORT = 'statistics.overview.export',
  STATISTICS_OVERVIEW_EXPORT_PROVISION = 'statistics.overview.export.provision',
  VENUE_CREATE = 'venue.create',
  VENUE_CREATE_SIMPLE = 'venue.create.simple',
  VENUE_UPDATE = 'venue.update',
  VENUE_UPDATE_SIMPLE = 'venue.update.simple',
  VENUE_DELETE = 'venue.delete',
  VENUE_EXPORT = 'venue.export',
  VENUE_ACTIVATE = 'venue.activate',
  VENUE_DEACTIVATE = 'venue.deactivate',
  VENUE_PANIC = 'venue.panic',
  VENUE_VIEW = 'venue.view',
  VENUE_PAYMENT = 'venue.payment',
  VENUE_VERIFY = 'venue.verify',
  VENUE_PUBLISH = 'venue.publish',
  VENUE_FILTER = 'venue.filter',
  VENUE_SYNC_START = 'venue.sync.start',
  VENUE_SYNC_STOP = 'venue.sync.stop',
  VENUE_SEPA_CREATE = 'venue.sepa.create',
  VENUE_SEPA_DELETE = 'venue.sepa.delete',
  VENUE_SEPA_PAY = 'venue.sepa.pay',
  VENUE_PAYPAL_AUTHORIZE = 'venue.paypal.authorize',
  VENUE_PAYPAL_CHECK = 'venue.paypal.check',
  VENUE_MIGRATE_DEV_TO_DEV = 'venue.migrate.devToDev',
  VENUE_MIGRATE_DEV_TO_PROD = 'venue.migrate.devToProd',
  VENUE_MIGRATE_PROD_TO_PROD = 'venue.migrate.prodToProd',
  VENUE_TRANSACTIONS_VIEW = 'venue.transactions.view',
  VENUE_TRANSACTIONS_CREATE = 'venue.transactions.create',
  VENUE_TRANSACTIONS_UPDATE = 'venue.transactions.update',
  VENUE_PARK_COLLECT_VIEW = 'venue.parkcollect.view',
  VENUE_PARK_COLLECT_UPDATE = 'venue.parkcollect.update',
  VENUE_SLUG_GENERATE = 'venue.slug.generate',
  VENUE_CHECKOUTINTEGRATION_VIEW = 'venue.checkoutIntegration.view',
  VENUE_CHECKOUTINTEGRATION_UPDATE = 'venue.checkoutIntegration.update',
  EMPLOYEE_CREATE = 'employee.create',
  EMPLOYEE_UPDATE = 'employee.update',
  EMPLOYEE_DELETE = 'employee.delete',
  EMPLOYEE_VIEW = 'employee.view',
  CUSTOMER_CREATE = 'customer.create',
  CUSTOMER_UPDATE = 'customer.update',
  CUSTOMER_DELETE = 'customer.delete',
  CUSTOMER_VIEW = 'customer.view',
  USER_CREATE = 'user.create',
  USER_CREATE_RESTRICTED = 'user.create.restricted',
  USER_UPDATE = 'user.update',
  USER_UPDATE_RESTRICTED = 'user.update.restricted',
  USER_DELETE = 'user.delete',
  USER_VIEW = 'user.view',
  USER_PASSWORD_RESET = 'user.password.reset',
  USER_PASSWORD_CHANGE = 'user.password.change',
  DASHBOARD_VIEW = 'dashboard.view',
  RECEIPT_CREATE = 'receipt.create',
  RECEIPT_UPDATE = 'receipt.update',
  RECEIPT_DELETE = 'receipt.delete',
  RECEIPT_CUSTOMER_EXPORT = 'receipt.customer.export',
  RECEIPT_MERCHANT_EXPORT = 'receipt.merchant.export',
  RECEIPT_FULL_EXPORT = 'receipt.full.export',
  RECEIPT_VIEW = 'receipt.view',
  PAGE_CREATE = 'page.create',
  PAGE_UPDATE = 'page.update',
  PAGE_DELETE = 'page.delete',
  PAGE_VIEW = 'page.view',
  SALES_CREATE = 'sales.create',
  SALES_UPDATE = 'sales.update',
  SALES_DELETE = 'sales.delete',
  SALES_EXPORT = 'sales.export',
  SALES_FLAVOR = 'sales.flavor',
  SALES_VIEW = 'sales.view',
  SALES_OVERVIEW = 'sales.overview',
  ORDER_CREATE = 'order.create',
  ORDER_UPDATE = 'order.update',
  ORDER_DELETE = 'order.delete',
  ORDER_VIEW = 'order.view',
  ORDER_EXPORT = 'order.export',
  ORDER_PAYMENTMETHOD_EXPORT = 'order.paymentmethod.export',
  ORDER_STATISTICS_EXPORT = 'order.statistics.export',
  ORDER_STATISTICS_EXPORT_NO_CUSTOMERS = 'order.statistics.export.noCustomers',
  ORDER_TEST = 'order.test',
  ORDER_RETRY = 'order.retry',
  ORDER_LOG_VIEW = 'order.log.view',
  INVOICE_VENUE = 'invoice.venue',
  INVOICE_SUMMARY = 'invoice.summary',
  INVOICE_SEND = 'invoice.send',
  INVOICE_VENUE_ARTICLES = 'invoice.venue.articles',
  REFUNDS = 'refunds',
  REFUNDS_SMOOTHR = 'refunds.smoothr',
  REFUNDS_CASH = 'refunds.cash',
  PAYMENT_VIEW = 'payment.view',
  PAYMENT_DATEV_EXPORT = 'payment.datev.export',
  MAINTENANCE_CREATE = 'maintenance.create',
  MAINTENANCE_UPDATE = 'maintenance.update',
  PROMO_CODE_CREATE = 'promoCode.create',
  PROMO_CODE_CREATE_OWN = 'promoCode.create.own',
  PROMO_CODE_VIEW = 'promoCode.view',
  PROMO_CODE_VIEW_GLOBAL = 'promoCode.view.global',
  PROMO_CODE_VIEW_SIMPLE = 'promoCode.view.simple',
  PROMO_CODE_UPDATE = 'promoCode.update',
  PROMO_CODE_ACTIVATE = 'promoCode.activate',
  PROMO_CODE_DEACTIVATE = 'promoCode.deactivate',
  PROMO_CODE_ENABLE = 'promoCode.enable',
  PROMO_CODE_DISABLE = 'promoCode.disable',
  PROMO_CODE_DELETE = 'promoCode.delete',
  PROMO_CODE_EXPORT = 'promoCode.export',
  PROMO_CODE_EXPORT_SUMMARY = 'promoCode.export.summary',
  PROMO_CODE_EXPORT_COUNT = 'promoCode.export.count',
  PROMO_CODE_EXPORT_OVERVIEW = 'promoCode.export.overview',
  PROMO_CODE_FLAVOR = 'promoCode.flavor',
  COUPON_CREATE = 'coupon.create',
  COUPON_CREATE_OWN = 'coupon.create.own',
  COUPON_VIEW = 'coupon.view',
  COUPON_VIEW_GLOBAL = 'coupon.view.global',
  COUPON_VIEW_SIMPLE = 'coupon.view.simple',
  COUPON_UPDATE = 'coupon.update',
  COUPON_ACTIVATE = 'coupon.activate',
  COUPON_DEACTIVATE = 'coupon.deactivate',
  COUPON_ENABLE = 'coupon.enable',
  COUPON_DISABLE = 'coupon.disable',
  COUPON_DELETE = 'coupon.delete',
  COUPON_EXPORT = 'coupon.export',
  COUPON_EXPORT_SUMMARY = 'coupon.export.summary',
  COUPON_EXPORT_COUNT = 'coupon.export.count',
  COUPON_EXPORT_OVERVIEW = 'coupon.export.overview',
  COUPON_FLAVOR = 'coupon.flavor',
  PROVISION_VIEW = 'provision.view',
  PROVISION_CLAIM = 'provision.claim',
  PROVISION_CLAIM_RESET = 'provision.claim.reset',
  PROVISION_LOGS = 'provision.logs',
  LABEL_CREATE = 'label.create',
  LABEL_UPDATE = 'label.update',
  LABEL_DELETE = 'label.delete',
  LABEL_VIEW = 'label.view',
  CLIENT_CREATE = 'client.create',
  CLIENT_UPDATE = 'client.update',
  CLIENT_DELETE = 'client.delete',
  CLIENT_VIEW = 'client.view',
  CLIENT_VIEW_SECRETS = 'client.view.secrets',
  CLIENT_BLOCK = 'client.block',
  CLIENT_UNBLOCK = 'client.unblock',
  CLIENT_REGISTER_ENABLE = 'client.register.enable',
  CLIENT_REGISTER_DISABLE = 'client.register.disable',
  INFORMATION_CREATE = 'information.create',
  INFORMATION_UPDATE = 'information.update',
  INFORMATION_DELETE = 'information.delete',
  INFORMATION_VIEW = 'information.view',
  REPORTING_VIEW = 'reporting.view',
  REPORTING_FLAVOR = 'reporting.flavor',
  DELIVERY_PROVIDER_ACTIVATION = 'deliveryProvider.activation',
  DELIVERY_PROVIDER_ENABLING = 'deliveryProvider.enabling',
  SCREENSAVER_CREATE = 'screensaver.create',
  SCREENSAVER_UPDATE = 'screensaver.update',
  SCREENSAVER_VIEW = 'screensaver.view',
  SCREENSAVER_DELETE = 'screensaver.delete',
  MEMBERSHIP_LEVELS_CREATE = 'membershiplevels.create',
  MEMBERSHIP_LEVELS_UPDATE = 'membershiplevels.update',
  MEMBERSHIP_LEVELS_DELETE = 'membershiplevels.delete',
  MEMBERSHIP_LEVELS_VIEW = 'membershiplevels.view',
  EPOS_JOB_CREATE = 'eposjob.create',
  EPOS_JOB_UPDATE = 'eposjob.update',
  EPOS_JOB_DELETE = 'eposjob.delete',
  EPOS_JOB_VIEW = 'eposjob.view',
  SANIFAIR_VIEW = 'sanifair.view',
  JOB_CREATE = 'job.create',
  JOB_UPDATE = 'job.update',
  JOB_DELETE = 'job.delete',
  JOB_VIEW = 'job.view',
  TIPS_VIEW = 'tips.view',
  LIGHTSPEED_AUTHORIZE = 'lightspeed_authorize',
  WOLT_FOODCARD_SYNC = 'wolt.foodcard.sync',
  WOLT_VENUE_STATUS = 'wolt.venue.status',
  WOLT_VENUE_OPENING_HOURS = 'wolt.venue.openingHours',
  WOLT_SYNC_JOB_VIEW = 'wolt.syncJob.view',
  WOLT_CONNECT = 'wolt.connect',
  WOLT_DISPATCH = 'wolt.dispatch',
  WOLT_OFFSETS = 'wolt.offsets',
  RECOMMENDATIONS_CREATE = 'recommendations.create',
  RECOMMENDATIONS_UPDATE = 'recommendations.update',
  RECOMMENDATIONS_DELETE = 'recommendations.delete',
  RECOMMENDATIONS_VIEW = 'recommendations.view',
  UBER_EATS_FOODCARD_SYNC = 'uber.eats.foodcard.sync',
  UBER_EATS_VENUE_STATUS = 'uber.eats.venue.status',
  UBER_EATS_VENUE_OPENING_HOURS = 'uber.eats.venue.openingHours',
  UBER_EATS_SYNC_JOB_VIEW = 'uber.eats.syncJob.view',
  UBER_EATS_CONNECT = 'uber.eats.connect',
  UBER_EATS_DISPATCH = 'uber.eats.dispatch',
  UBER_EATS_OFFSETS = 'uber.eats.offsets',
  JET_FOODCARD_SYNC = 'jet.foodcard.sync',
  JET_VENUE_STATUS = 'jet.venue.status',
  JET_VENUE_OPENING_HOURS = 'jet.venue.openingHours',
  JET_SYNC_JOB_VIEW = 'jet.syncJob.view',
  JET_DISPATCH = 'jet.dispatch',
  JET_SETTINGS = 'jet.settings',
  INVENTORY_CREATE = 'inventory.create',
  INVENTORY_VIEW = 'inventory.view',
  INVENTORY_UPDATE = 'inventory.update',
  INVENTORY_DELETE = 'inventory.delete',
  DND_COMPANY_CREATE = 'dnd.company.create',
  DND_COMPANY_VIEW = 'dnd.company.view',
  DND_COMPANY_UPDATE = 'dnd.company.update',
  DND_COMPANY_DELETE = 'dnd.company.delete',
  UI_CARD_CREATE = 'ui.card.create',
  UI_CARD_VIEW = 'ui.card.view',
  UI_CARD_UPDATE = 'ui.card.update',
  UI_CARD_DELETE = 'ui.card.delete',
  UI_SLIDER_CREATE = 'ui.slider.create',
  UI_SLIDER_VIEW = 'ui.slider.view',
  UI_SLIDER_UPDATE = 'ui.slider.update',
  UI_SLIDER_DELETE = 'ui.slider.delete',
  UI_BANNER_CREATE = 'ui.banner.create',
  UI_BANNER_VIEW = 'ui.banner.view',
  UI_BANNER_UPDATE = 'ui.banner.update',
  UI_BANNER_DELETE = 'ui.banner.delete',
  MFA_ENABLE = 'mfa.enable',
  MFA_DISABLE = 'mfa.disable',
  CAMPAIGN_CREATE = 'campaign.create',
  CAMPAIGN_VIEW = 'campaign.view',
  CAMPAIGN_UPDATE = 'campaign.update',
  CAMPAIGN_DELETE = 'campaign.delete',
  ORDER_POOL_VIEW = 'order.pool.view',
  ORDER_POOL_UPDATE = 'order.pool.update',
}
